import { signIn, useSession } from 'next-auth/react';
import { RcButton } from '@rc-d2c/shared-ui/RcButton';
import { handleLogout } from '../../helpers/authentication';
import { useStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
import { UserStore } from '@rc-d2c/context-manager';
import styles from '../../styles/common.module.scss';

function Login() {
  const { status, data: session } = useSession();
  const isAuthenticated = status === 'authenticated';
  const isMswEnable = useStore(UserStore, ({ isMswEnable }) => isMswEnable);

  const logout = async () => {
    handleLogout(session);
  };

  const handleClick = () => (isAuthenticated ? logout() : signIn('okta'));

  if (isMswEnable) {
    return <div className={styles.mswStatus}>MSW is ACTIVE !!</div>;
  }

  return <RcButton label={isAuthenticated ? 'Sign out' : 'Sign in'} onClick={handleClick} />;
}

export default Login;
