import { COUNTRIES } from '@rc-d2c/context-manager';
import { useLocalStorage } from 'usehooks-ts';

export const getDefaultStore = () => {
  return COUNTRIES.NL;
};

export const useStoreCountry = (args?: { key?: string; value?: COUNTRIES }) => {
  const { key = 'app-shell-store', value = getDefaultStore() } = args || {};

  const [storeCountry, setStoreCountry] = useLocalStorage<COUNTRIES>(key, value);
  return { storeCountry, setStoreCountry };
};
