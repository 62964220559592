import type { FederationRuntimePlugin } from '@module-federation/enhanced/runtime';
import type Module from 'module';

import type { getShareFromUnpkgType } from './npm-runtime-global-plugin-factory';
import npmRuntimeGlobalPluginFactory from './npm-runtime-global-plugin-factory';

const getShareFromUnpkg: getShareFromUnpkgType = ({ packageName, version, manifestPromise }) => {
  return async () => {
    const manifest = await manifestPromise();
    if (!manifest) {
      throw new Error('Manifest is not available to load shared library');
    }
    const sharedLib = manifest['shared-lib'];
    const contextManagerLink = sharedLib['context-manager'];
    const scriptUrl = contextManagerLink.esm;
    // eslint-disable-next-line no-new-func
    const mod = new Function('packageName', 'version', `return import(/* webpackIgnore: true */ '${scriptUrl}')`)(packageName, version);
    return mod.then((m: Module) => {
      return () => m;
    });
  };
};

export default function (): FederationRuntimePlugin {
  return npmRuntimeGlobalPluginFactory(getShareFromUnpkg, 'browser');
}
